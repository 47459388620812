import React from 'react';
import NumberFormat from 'react-number-format';
import Range from './Range';

const FormField = (props) => {
    const { 
        text, 
        type, 
        value, 
        small, 
        update, 
        className, 
        readOnly, 
        disabled, 
        max, 
        saveToGoogleSpreadsheet,
    } = props;

    const onChange = (event) =>{
        update(event.target.value.replace(/[^0-9]/g, ''))
    }
    const onClickButton = (event) => {
        saveToGoogleSpreadsheet();
    }
    return (
        <div className="box">
            {
                type ==="button" && <button className={className} disabled={disabled} onClick={onClickButton}>{text}</button>
                
            }
            { type !== "button" && 
                <div className="row">
                    <div className="col-9">
                        {text}
                        {
                            small && (
                                <div>
                                    <small>{small}</small>
                                </div>
                            )
                        }
                    </div>
                    <div className="col-3">
                        {
                            type ==="text" && <input 
                                                type="text"
                                                onChange={onChange} 
                                                defaultValue={value && value.toLocaleString()} 
                                                readOnly={readOnly} 
                                                placeholder={type === "number" ? 0 : ''}
                                            />
                        }
                        {
                            type === "number" && <NumberFormat 
                                                thousandSeparator={true} 
                                                type="text"
                                                onChange={onChange} 
                                                value={value && value.toLocaleString()} 
                                                readOnly={readOnly} 
                                                placeholder={type === "number" ? 0 : ''}
                                                ref={props.ref}
                                            />
                        }
                        {
                            type ==="money" && <NumberFormat 
                                                    thousandSeparator={true} 
                                                    prefix={'$'} 
                                                    type="text"
                                                    onChange={onChange} 
                                                    value={value && value.toLocaleString()} 
                                                    readOnly={readOnly} 
                                                    placeholder="$0"
                                                />
                        }
                        {
                            type ==="percentage" && <NumberFormat 
                                                thousandSeparator={true} 
                                                type="text" 
                                                onChange={onChange} 
                                                value={value && value.toLocaleString()} 
                                                readOnly={readOnly} 
                                                placeholder="0%"
                                                format={
                                                    (val) => {
                                                        return val + " %";
                                                    }
                                                }
                                            />
                        }
                    </div>
                </div>
            }
            { type !== "text" && type !== "button" &&
                <div className="row">
                    <div className="col-12">
                        <Range 
                            type="range" 
                            min="0" 
                            max={max}
                            value={value ? value : 0}
                            onChange={onChange}
                        />
                    </div>
                    {/** 
                    <div className="col-12">
                        <span className="minValue float-start">
                            {0}
                        </span>
                        <span className="maxValue float-end">
                            {max.toLocaleString()}
                        </span>
                    </div>
                    */}
                </div>
            }
        </div>
    );
}

export default FormField