import React, { useState, useRef } from 'react';
import ContactPanel from './ContactPanel';

const Result = ({
    D28,
    D25,
    D26,
    E23,
    C23,
    F23,
    D24,
    uuid
}) => {
    const [sendCalculation, setSendCalculation] = useState(false)
    const focusRef = useRef(null)
    const emailContext = `
        <ul>
            <li>A digital employee can do the work of ${Math.round(D28).toLocaleString()} full time agent(s) at your company annually.</li>
            <li>It can take the load of ${D25.toLocaleString()} enquiries off your agents hands.</li>
            <li>It will free up ${Math.round(D26).toLocaleString()} hours in a year for your agents to use on more important actions.</li>
            <li>The digital employee will cost $${parseFloat(E23).toFixed(2).toLocaleString()} to serve per enquiry; alongside your agent which costs $${parseFloat(C23).toFixed(2)} to serve per enquiry. That's ${Math.round(F23*100)}% more cost effective. Even better, a digital employee can handle many enquires simultaneously while your agent only handles one at a time.</li>
            <li>With an annual cost saving of $${D24.toLocaleString()} per year, it's worth considering one for your team and your customers. Don't you think?</li>
        </ul>
    `
    const simpleContext = `
        A digital employee can do the work of ${Math.round(D28).toLocaleString()} full time agent(s) at your company annually.
        It can take the load of ${D25.toLocaleString()} enquiries off your agents hands.
        It will free up ${Math.round(D26).toLocaleString()} hours in a year for your agents to use on more important actions.
        The digital employee will cost $${parseFloat(E23).toFixed(2).toLocaleString()} to serve per enquiry; alongside your agent which costs $${parseFloat(C23).toFixed(2)} to serve per enquiry. That's ${Math.round(F23*100)}% more cost effective. Even better, a digital employee can handle many enquires simultaneously while your agent only handles one at a time.
        With an annual cost saving of $${D24.toLocaleString()} per year, it's worth considering one for your team and your customers. Don't you think?
    `

    return (
        <div ref={focusRef}>
            { 
                !sendCalculation && 
                    <div>
                        <div>
                            <div>
                                <h2>Time and money saved by adding an Ambit Digital Employee to your Customer Service & Support team</h2>
                                <h3>Based on the information you input into the calculator, these are the results you could get for your business:</h3>
                                <div dangerouslySetInnerHTML={{__html: emailContext}}></div>
                                <h3>We find this information helpful when making a business case to see if a Digital Employee is worth adding to your team.</h3>
                                <h3>If you'd like these results sent to your email, together with more of the calculations done, click the button below:</h3>
                            </div>
                        </div>
                        <div>
                            <button onClick={() => { focusRef.current.scrollIntoView(); setSendCalculation(true); }}>Send the calculations</button>
                        </div>
                    </div>
            }
            {
                sendCalculation && <ContactPanel simpleContext={simpleContext} context={emailContext} uuid={uuid} />
            }
        </div>
    )
}

export default Result;