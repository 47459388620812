import React, { useState, useEffect, useRef } from 'react';
import FormField from './FormField';
import axios from 'axios';
import ReactDOM from 'react-dom';

const Calculator = (props) => {
    const isFirstRun = useRef(true);

    const [number_staff, setNumber_staff] = useState()
    const [average_cost_team_member, setAverage_cost_team_member] = useState()
    const [work_hours, setWork_hours] = useState()
    const [numberOfEmails, setNumberOfEmails] = useState()
    const [numberOfCalls, setNumberOfCalls] = useState()
    const [numberOfChats, setNumberOfChats] = useState()
    const [ticket_number, setTicket_number] = useState()
    const [average_time, setAverage_time] = useState()
    const [reduce, setReduce] = useState()

    const [validAllField, setValidAllField] = useState(false)

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const [spreadSheetValues, setSpreadSheetValues] = useState({})

    const focusRef = useRef(null)

    useEffect(()=>{
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        if( number_staff 
            && average_cost_team_member 
            && work_hours 
            && (Number(numberOfEmails || 0) + Number(numberOfCalls || 0) + Number(numberOfChats || 0)) 
            && ticket_number 
            && average_time 
            && reduce){
                setValidAllField(true)
        }else{
            setValidAllField(false)
        }

        const { 
            setD28,
            setD25,
            setD26,
            setE23,
            setC23,
            setF23,
            setD24,
        } = props;

        const C8 = (average_cost_team_member / 52) / work_hours;
        const C14 = (Number(numberOfEmails || 0) + Number(numberOfCalls || 0) + Number(numberOfChats || 0))
        const D25 = (reduce/100)*(C14*12)
        const D7 = number_staff * average_cost_team_member;
        const E6 = 78000;

        const E23 = E6/(C14*12);
        const C23 = D7 / (C14*12);
        const D22 = D25 * C23;
        const E22 = D25 * E23;
        const F23 = (C23-E23)/C23;
        const D24 = D22-E22;
        const D26 = D24/C8;
        //const D27 = (D26/number_staff)/12;
        const D28 = (D26/52)/40;

        setD28(D28)
        setD25(D25)
        setD26(D26)
        setE23(E23)
        setC23(C23)
        setF23(F23)
        setD24(D24)

        setSpreadSheetValues({
            D28,
            D25,
            D26,
            E23,
            C23,
            F23,
            D24
        })
    },[number_staff, average_cost_team_member, work_hours, numberOfEmails, numberOfCalls, numberOfChats, ticket_number, average_time, reduce, props])

     const saveToGoogleSpreadsheet = () => {
        focusRef.current.scrollIntoView();

        const {
            D28,
            D25,
            D26,
            E23,
            C23,
            F23,
            D24
        } = spreadSheetValues;

        setLoading(true)

        axios.post('https://y191gzfnk2.execute-api.ap-southeast-2.amazonaws.com/dev/spreadsheet', {
            field1: number_staff,
            field2: average_cost_team_member,
            field3: work_hours,
            field4: numberOfEmails,
            field5: numberOfCalls,
            field6: numberOfChats,
            field7: ticket_number,
            field8: average_time,
            field9: reduce,
            field10: D28,
            field11: D25,
            field12: D26,
            field13: E23,
            field14: C23,
            field15: F23,
            field16: D24,
            field17: props.uuid
        })
        .then(function (response) {
            if(response.status === 200){
                props.setCalculate(true)
                setError(false)
            }else{
                setError(true)
            }
            setLoading(false)
        })
     }

    return (
        <div ref={focusRef} className={loading ? 'loading': ''}>
            <h3>Enter your calculations below</h3>
            <FormField
                update={setNumber_staff} 
                value={number_staff}
                type="number" 
                text="How many full time support agents do you have?"
                max={1000}
            />
            <FormField 
                update={setAverage_cost_team_member} 
                value={average_cost_team_member}
                type="money" 
                text="What's the average annual cost of an agent?" 
                max={150000}
            />
            <FormField 
                update={setWork_hours}
                value={work_hours}
                type="number" 
                text="How many average hours does an agent need work in a week?" 
                max={80}
            />
            <p>How many total enquiries do you get in a month?</p>
            <FormField 
                type="number"
                value={numberOfEmails}
                update={setNumberOfEmails}
                small="Approximate number of emails"
                max={500000}
            />
            <FormField 
                type="number"
                value={numberOfCalls}
                update={setNumberOfCalls}
                small="Approximate number of calls"
                max={100000000}
            />
            <FormField 
                type="number"
                value={numberOfChats}
                update={setNumberOfChats}
                small="Approximate number of live chats"
                max={100000000}
            />
            <FormField 
                update={setTicket_number}
                value={ticket_number}
                type="number" 
                text="How many enquiries does your agent handle in an hour?"
                max={50}
            />
            <FormField 
                update={setAverage_time}
                value={average_time}
                type="number" 
                text="On average how many minutes does it take for your agents to handle a level 1 enquiry?"
                max={180}
            />
            <FormField 
                type="percentage"
                update={setReduce}
                value={reduce}
                text="What % do you want to reduce enquiries by?"
                small="Anything from 10% to 45% is achievable"
                max={45}
            />
            <FormField 
                saveToGoogleSpreadsheet={saveToGoogleSpreadsheet}
                type="button"
                text={loading?"Calculating...":"Calculate"}
                disabled={validAllField ? false : true} 
                className={loading ? 'loading' : ''}
            />
            {
                error ? <p className="error">Something wrong with the system. Please contact to Ambit!</p> : ''
            }
        </div>
    );
}

export default Calculator
