import React, { useEffect, useState } from 'react';
import './App.css';
import Calculator from './Calculator';
import Result from './Result';
import { v4 as uuidv4 } from 'uuid';

const uuidV4 = uuidv4()

const App = () => {
  // refer below url what below variable means
  // https://docs.google.com/spreadsheets/d/1LMbRFLziEr6kTzQKUhvXHqW2gQZledibjg_XrFj0a04/edit#gid=1209428445
  const [ D28, setD28 ] = useState(0)
  const [ D25, setD25 ] = useState(0)
  const [ D26, setD26 ] = useState(0)
  const [ E23, setE23 ] = useState(0)
  const [ C23, setC23 ] = useState(0)
  const [ F23, setF23 ] = useState(0)
  const [ D24, setD24 ] = useState(0)

  const [ calculated, setCalculate ] = useState(false)

  useEffect(() => {
    
  })
  return (
    <div className="App">
      { !calculated && (
          <Calculator 
            setD28={setD28}
            setD25={setD25}
            setD26={setD26}
            setE23={setE23}
            setC23={setC23}
            setF23={setF23}
            setD24={setD24}
            setCalculate={setCalculate}
            calculated={calculated}
            uuid={uuidV4}
          />
      ) }
      {
        calculated && (
          <Result 
            D28={D28 ? D28 : 0}
            D25={D25 ? D25 : 0}
            D26={D26 ? D26 : 0}
            E23={E23 ? E23 : 0}
            C23={C23 ? C23 : 0}
            F23={F23 ? F23 : 0}
            D24={D24 ? D24 : 0}
            uuid={uuidV4}
          />
        )
      }
      
      
    </div>
  );
}

export default App;
