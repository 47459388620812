import axios from 'axios';
import React, { useState, useRef } from 'react';

const ContactPanel = ({ context, uuid, simpleContext }) => {
    const [ firstName, setFirstName ] = useState('')
    const [ lastName, setLastName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ job, setJob ] = useState('')
    const [ companyName, setCompanyName ] = useState('')
    const [ companySize, setCompanySize ] = useState('')
    const [ industry, setIndustry ] = useState('')
    const [ subscribe, setSubscribe ] = useState(false) 
    const [ submitted, setSubmitted ] = useState(false)

    const [ error, setError ] = useState(false)
    const [ loading, setLoading ] =useState(false)

    const focusRef = useRef(null)

    const onSubmit = () => {

        setLoading(true)

        formv3()
        sendEmail()
        setSubmitted(true)
        focusRef.current.scrollIntoView();
        setLoading(false)

    }

    const formv3 = () => {
        // Create the new request 
        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/6207230/d30d851e-57a3-4364-aa64-054d7ac2ef3b'
        
        // Example request JSON:
        var data = {
          "fields": [
            {
              "name": "email",
              "value": email
            },  
            {
              "name": "company_email",
              "value": email
            },
            {
              "name": "firstname",
              "value": firstName
            },
            {
              "name": "lastname",
              "value": lastName
            },
            {
              "name": "company_size",
              "value": companySize
            },
            {
              "name": "company",
              "value": companyName
            },
            {
              "name": "0-2/industry",
              "value": industry
            },
            {
              "name": "mobilephone",
              "value": phone
            },
            {
              "name": "website",
              "value": job
            },
            {
              "name": "would_you_like_us_to_call_you_for_a_15_min_chat_",
              "value": subscribe
            },
            {
              "name": "cost_to_serve_calculator_id",
              "value": uuid
            },
            {
                "name" : "cost_to_serve_calculator_thank_you_page",
                "value" : window.location.href
            },
            {
                "name" : "cost_to_serve_results_in_email",
                "value" : simpleContext
            }
          ]
        }
    
        var final_data = JSON.stringify(data)
    
        xhr.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json');
    
        xhr.onreadystatechange = function() {
            if(xhr.readyState == 4 && xhr.status == 200) { 
                //alert(xhr.responseText); // Returns a 200 response if the submission is successful.
                return true
            } else if (xhr.readyState == 4 && xhr.status == 400){ 
                setError(xhr.responseText); // Returns a 400 error the submission is rejected. 
                return false         
            } else if (xhr.readyState == 4 && xhr.status == 403){ 
                setError(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.           
                return false
            } else if (xhr.readyState == 4 && xhr.status == 404){ 
                setError(xhr.responseText); //Returns a 404 error if the formGuid isn't found   
                return false  
            }
           }
    
    
        // Sends the request 
        
        xhr.send(final_data)
     }

    const sendEmail = () => {
        axios.post('https://y191gzfnk2.execute-api.ap-southeast-2.amazonaws.com/dev/send', {
            text: context,
            name: firstName,
            title: "Ambit - Cost to Serve Calculator result",
            recipient: email
        }).then(function (response) {
            if(response.status === 200){
                setError(false)
            }else{
                setError(response.data.text)
            }
        })
    }

    return (
        <div ref={focusRef} className={loading ? 'panel loading' : 'panel'}>
            { error && <p className="error">{error}</p> }
            {
                submitted && <p>Thanks for submitting your details. Check your inbox for the email with the results.</p>
            }
            { !submitted && 
                <div>
                    <h3>Complete the form below if you'd like us to send these calculaltions and results to you in an email for your business case.</h3>
                    <div className="box">
                        <input
                            type="text" 
                            placeholder="First name"
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>
                    <div className="box">
                        <input
                            type="text" 
                            placeholder="Last name"
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                    <div className="box">
                        <input
                            type="text" 
                            placeholder="Business email address"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="box">
                        <input
                            type="text" 
                            placeholder="Mobile phone number"
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                    <div className="box">
                        <select
                            onChange={(e) => setJob(e.target.value)}
                        >
                            <option selected disabled hidden>Job Role</option>
                            <option>Customer Service Manager</option>
                            <option>Customer Service Agent</option>
                            <option>Customer Support Manager</option>
                            <option>Customer Support Agent</option>
                            <option>Marketer</option>
                            <option>Marketing Manager</option>
                            <option>Operations Manager</option>
                            <option>Customer Experience Manager</option>
                            <option>HR Manager</option>
                            <option>CEO</option>
                            <option>CIO</option>
                            <option>CFO</option>
                            <option>CDO</option>
                            <option>Other...</option>
                        </select>
                    </div>
                    <div className="box">
                        <input
                            type="text" 
                            placeholder="Company name"
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                    </div>
                    <div className="box">
                        <select
                            onChange={(e) => setCompanySize(e.target.value)}
                        >
                            <option selected disabled hidden>Company size</option>
                            <option>1-20</option>
                            <option>21-50</option>
                            <option>50-100</option>
                            <option>501-1000</option>
                            <option>1001-1500</option>
                            <option>1501-2000</option>
                            <option>More...</option>
                        </select>
                    </div>
                    <div className="box">
                        <select
                            type="text" 
                            onChange={(e) => setIndustry(e.target.value)}
                        >
                            <option selected disabled hidden>Industry</option>
                            <option>Retail and eCommerce</option>
                            <option>Hospitality & Tourism</option>
                            <option>Financial Services</option>
                            <option>Manufacturing</option>
                            <option>Healthcare</option>
                            <option>Education</option>
                            <option>Energy and Utilities</option>
                            <option>Telecommunications</option>
                            <option>Other...</option>
                        </select>
                    </div>
                    <div className="box">
                        <button disabled={ firstName && lastName && email ? false : true } onClick={onSubmit}>Submit</button>
                    </div>
                    <p>By submitting your email to us, you agree to receive marketing emails from Ambit. You can unsubscribe at any time.</p>
                    <label><input type="checkbox" onClick={() => setSubscribe(!subscribe)}/> Would you like us to call you for a 15min chat?</label>
                </div>
            }
        </div>
    )
}

export default ContactPanel;